import { FC, useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useStores } from '@/utils'
import { DbPaymentLightPlan } from '@/components/molecules/DbPaymentLightPlan'
import { i18n, useTranslation } from 'next-i18next'
import { DbPaymentStandardPlan } from '@/components/molecules/DbPaymentStandardPlan'
import { DbPaymentPremiumPlan } from '@/components/molecules/DbPaymentPremiumPlan'
import { DbPaymentTestPlan } from '@/components/molecules/DbPaymentTestPlan'
import styles from './index.module.scss'

type Props = {
  onClose?: () => void
}

export const DbPaymentModal: FC<Props> = observer(({ onClose }) => {
  const { t } = useTranslation(['global'])
  const locale = i18n.language
  const { ui, viewer } = useStores()
  const [monthlyType, setMonthlyType] = useState(true)

  const closeModal = () => {
    ui.toggleDbPaymentModal()

    // コールバックがあれば実行
    if (onClose) {
      onClose()
    }
  }

  return (
    <div
      className={classNames(styles.modal, {
        [styles.activeModal]: ui.isDbPaymentModalOpen,
      })}
    >
      <div className={styles.container}>
        <div className={styles.inner}>
          <div className={styles.content}>
            <div className={styles.body}>
              <div className={styles.closeButton} onClick={closeModal} aria-hidden='true' role='button'>
                <img src='/images/common/close.svg' alt='Close' className='img-fluid' />
              </div>
              <div className={styles.title}>
                <p className={styles.titleTop}>Price</p>
                <p className={styles.titleBottom}>{t('料金プラン')}</p>
              </div>
              <div className={styles.selector}>
                <div
                  className={classNames(styles.planType, {
                    [styles.active]: monthlyType,
                  })}
                  onClick={() => setMonthlyType(true)}
                  role='button'
                  aria-hidden='true'
                >
                  <p
                    className={classNames(styles.selectorText, {
                      [styles.activeText]: monthlyType,
                    })}
                  >
                    {t('月々払い')}
                  </p>
                </div>
                <div
                  className={classNames(styles.planType, {
                    [styles.active]: !monthlyType,
                  })}
                  onClick={() => setMonthlyType(false)}
                  role='button'
                  aria-hidden='true'
                >
                  <p
                    className={classNames(styles.selectorText, {
                      [styles.activeText]: !monthlyType,
                    })}
                  >
                    {t('一括払い')}
                  </p>
                </div>
              </div>
              <div className={styles.plans}>
                {locale === 'ja' && (
                  <>
                    <DbPaymentLightPlan monthlyType={monthlyType} />
                    <DbPaymentStandardPlan monthlyType={monthlyType} />
                    <DbPaymentPremiumPlan monthlyType={monthlyType} />
                    {viewer.viewer.id === '8' && <DbPaymentTestPlan monthlyType={monthlyType} />}
                  </>
                )}
                {locale === 'en' && (
                  <>
                    <DbPaymentLightPlan monthlyType={monthlyType} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
