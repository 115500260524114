import { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { DbPrice, MessageType } from '@/types'
import { i18n, useTranslation } from 'next-i18next'
import { LoadingIndicator } from '@/components/atoms/LoadingIndicator'
import classNames from 'classnames'
import { useStores, sanitizeUrl } from '@/utils'
import { useRouter } from 'next/router'
import styles from './index.module.scss'

type Props = {
  monthlyType: boolean
}

export const DbPaymentTestPlan: FC<Props> = observer(({ monthlyType }) => {
  const { t } = useTranslation(['global'])
  const locale = i18n.language
  const { viewer, messages } = useStores()
  const [isLoading, setIsLoading] = useState(false)
  const router = useRouter()

  const LinkToPayment = async () => {
    setIsLoading(true)
    const output = await viewer.viewer?.createCheckoutSession({
      userId: viewer.viewer.id,
      priceType: DbPrice.PRODUCTION_TEST,
    })
    setIsLoading(false)
    if (!output) {
      messages.add({
        type: MessageType.Error,
        body: t('支払い画面への遷移時にエラーが発生しました。サポートへお問い合わせください。'),
        isTranslated: true,
        isDismissable: true,
        ttl: 5000,
      })
      return
    }
    const link = locale === 'ja' ? `${sanitizeUrl(output.sessionUrl)}` : `${sanitizeUrl(output.sessionUrl)}?locale=en`
    router.push(link)
  }

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <p className={styles.planTitle}>{t('テストプラン')}</p>
        <p className={styles.planText}>
          {t('投資会社へのアタックリスト作成とインバウンドでのアポ獲得を効率化したいスタートアップ向け')}
        </p>
        <div className={styles.detail}>
          <div className={styles.item}>
            <div className={styles.check} />
            <p className={styles.itemText}>{t('ライトプランの内容')}</p>
          </div>
          <div className={styles.item}>
            <div className={styles.check} />
            <p className={styles.itemText}>{t('インタビュー記事の作成とPROTOCOLプラットフォームでの公開')}</p>
          </div>
          <div className={styles.item}>
            <div className={styles.check} />
            <p className={styles.itemText}>{t('投資会社リスト作成代行')}</p>
          </div>
          <div className={styles.item}>
            <div className={styles.check} />
            <p className={styles.itemText}>{t('アプローチ文章作成')}</p>
          </div>
          <div className={styles.item}>
            <div className={styles.check} />
            <p className={styles.itemText}>
              {t('四半期ごとの投資家向け情報配信と、関心を寄せた投資会社からの直接オファー')}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.price}>
          <div className={styles.cost}>
            <p className={styles.number}>{monthlyType ? t('¥50,000') : t('¥550,000')}</p>
            <p className={styles.tax}>{t('(税抜)')}</p>
            <p className={styles.per}>{monthlyType ? t('/月') : t('/年')}</p>
          </div>
          <p className={styles.priceDescription}>{monthlyType ? t('※12ヶ月契約') : t('※1ヶ月分お得')}</p>
        </div>
        <button
          type='button'
          className={classNames(styles.link, {
            [styles.loading]: isLoading,
          })}
          onClick={LinkToPayment}
        >
          <div
            className={classNames(styles.btnContent, {
              [styles.loading]: isLoading,
            })}
          >
            <p className={styles.linkText}>
              {monthlyType ? t('月々払いで利用を開始する') : t('一括払いで利用を開始する')}
            </p>
          </div>
          {isLoading && (
            <div className={styles.loadingIndicator}>
              <LoadingIndicator size='small' color='primary' />
            </div>
          )}
        </button>
      </div>
    </div>
  )
})
