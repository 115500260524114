import { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { DbPrice, MessageType } from '@/types'
import { i18n, useTranslation } from 'next-i18next'
import { LoadingIndicator } from '@/components/atoms/LoadingIndicator'
import classNames from 'classnames'
import { useStores, sanitizeUrl } from '@/utils'
import { useRouter } from 'next/router'
import styles from './index.module.scss'

type Props = {
  monthlyType: boolean
}

export const DbPaymentPremiumPlan: FC<Props> = observer(({ monthlyType }) => {
  const { t } = useTranslation(['global'])
  const locale = i18n.language
  const { viewer, messages } = useStores()
  const [isLoading, setIsLoading] = useState(false)
  const router = useRouter()

  const LinkToPayment = async () => {
    setIsLoading(true)
    const output = await viewer.viewer?.createCheckoutSession({
      userId: viewer.viewer.id,
      priceType: DbPrice.PREMIUM_MONTHLY_JPY,
    })
    setIsLoading(false)
    if (!output) {
      messages.add({
        type: MessageType.Error,
        body: t('支払い画面への遷移時にエラーが発生しました。サポートへお問い合わせください。'),
        isTranslated: true,
        isDismissable: true,
        ttl: 5000,
      })
      return
    }
    const link = locale === 'ja' ? `${sanitizeUrl(output.sessionUrl)}` : `${sanitizeUrl(output.sessionUrl)}?locale=en`
    router.push(link)
  }
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <p className={styles.planTitle}>{t('プレミアムプラン')}</p>
        <p className={styles.planText}>
          {t('投資会社へのアタックリスト作成とアポ獲得に加え、成約率の向上を図りたいスタートアップ向け')}
        </p>
        <div className={styles.detail}>
          <div className={styles.item}>
            <div className={styles.check} />
            <p className={styles.itemText}>{t('スタンダートプランの内容')}</p>
          </div>
          <div className={styles.item}>
            <div className={styles.check} />
            <p className={styles.itemText}>{t('専属コンサルタントのアサイン')}</p>
          </div>
          <div className={styles.item}>
            <div className={styles.check} />
            <p className={styles.itemText}>{t('プロデザイナーによる資金調達資料の作成')}</p>
          </div>
          <div className={styles.item}>
            <div className={styles.check} />
            <p className={styles.itemText}>{t('プレゼンテーションの指導')}</p>
          </div>
          <div className={styles.item}>
            <div className={styles.check} />
            <p className={styles.itemText}>{t('DD対応サポート')}</p>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <p className={styles.bottomText}>
          {t('お客様のニーズに合わせて、最適なサービス内容と料金をカスタマイズしてご提案いたします。')}
        </p>
        {viewer.viewer.hasStripeCustomerId && (
          <button
            type='button'
            className={classNames(styles.link, {
              [styles.loading]: isLoading,
              [styles.disabled]: !monthlyType,
            })}
            onClick={LinkToPayment}
            disabled={!monthlyType}
          >
            <div
              className={classNames(styles.btnContent, {
                [styles.loading]: isLoading,
              })}
            >
              <p className={styles.linkText}>
                {monthlyType ? t('月々払いで利用を開始する') : t('一括払いで利用を開始する')}
              </p>
            </div>
            {isLoading && (
              <div className={styles.loadingIndicator}>
                <LoadingIndicator size='small' color='primary' />
              </div>
            )}
          </button>
        )}
        {!viewer.viewer.hasStripeCustomerId && (
          <a
            type='button'
            className={styles.link}
            href={sanitizeUrl(`${process.env.NEXT_PUBLIC_CONTACT_URL}`)}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div
              className={classNames(styles.btnContent, {
                [styles.loading]: isLoading,
              })}
            >
              <p className={styles.linkText}>{t('担当者との面談を予約する')}</p>
            </div>
          </a>
        )}
      </div>
    </div>
  )
})
